/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// react components for routing our app without refresh
//import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import { Apps } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button href="/#o-nas" color="transparent" className={classes.navLink}>
          <i className={classes.icons + " fab fa-pagelines"} /> O nas
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Usługi"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <a href="/#projektowanie" target="" className={classes.dropdownLink}>
              Projektowanie zieleni
            </a>,
            <a href="/#ochrona" target="" className={classes.dropdownLink}>
              Ochrona środowiska
            </a>,
            <a href="/#wykonanie" target="" className={classes.dropdownLink}>
            Wykonanie zieleni
            </a>,
            <a href="/#pielegnacja" target="" className={classes.dropdownLink}>
            Pielęgnacja i utrzymanie zieleni
            </a>,
            <a href="/#zielen-drogowa" target="" className={classes.dropdownLink}>
            Zieleń drogowa
            </a>,
            <a href="/#wynajem-sprzetu" target="" className={classes.dropdownLink}>
              Wynajem sprzętu
            </a>
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          href="/#kontakt"
          color="transparent"
          className={classes.navLink}
        >
          <i className={classes.icons + " fab fa-envira"} /> Kontakt
        </Button>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
