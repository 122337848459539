import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
//import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CookieConsent from "react-cookie-consent";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classNames from "classnames";
//import SectionPrivacy from "./Sections/SectionPrivacy.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

const dashboardRoutes = [];

class NotFound extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hortus_Architektura_Zieleni_Ochrona_srodowiska_Elk.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1 className={classes.title}>
                  404 Strona nie istnieje
                </h1>
                <h4>
                  Hortus Architektura Zieleni Ochrona Środowiska Ełk.
                  Usługi świadczymy na terenie całej Polski, współpracując z
                  osobami prywatnymi, firmami, instytucjami oraz spółdzielniami
                  mieszkaniowymi. Aby poznać więcej szczegółów naszej oferty lub
                  umówić się z nami na spotkanie, prosimy o kontakt telefoniczny
                  bądź mailowy!
                </h4>
                <br />
                <Button
                  color="success"
                  size="lg"
                  href="/#kontakt"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-envira" />
                  Kontakt
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          </div>
        </div>

        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ akceptuję"
            cookieName="hellasfonCookie"
            style={{ background: "#4caf50" }}
            buttonStyle={{ background: "#386f3a", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Używamy plików cookie na tej stronie, aby poprawić komfort użytkowania.{" "}
            <span style={{ fontSize: "12px" }}>
            Kliknięcie dowolnego linku na tej stronie wyraża zgodę na pliki cookie.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Więcej informacji</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(NotFound);
