import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import LandingPage from "./views/LandingPage";
import Privacy from "./views/PrivacyPage";
import NotFound from "./views/NotFound";

import "assets/scss/material-kit-react.scss?v=1.7.0";

var hist = createBrowserHistory();

function App() {
  return (
    <Router history={hist}>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy" component={Privacy} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
