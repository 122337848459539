/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { title } from "assets/jss/material-kit-react.jsx";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const workStyle = {
  section: {
    padding: "10px 0"
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  descriptionTitle: {
    color: "#000",
    textAlign: "center"
  },
  description: {
    color: "#000",
    fontWeight: "400"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  labelCheckbox: {
    fontSize: "12px"
  },
  buttonContainer: {
    display: "contents"
  },
  iconBig: {
    fontSize: "26px"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  } ,
  successSnackbar:{
    backgroundColor: green[600]
  },
  errorSnackbar: {
    backgroundColor: red[600]
  },
  hideSnackbar: {
    display: "none"
  }
};

export default workStyle;
