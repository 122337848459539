import React from "react";
// react component for creating beautiful carousel
// material-ui components
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";


import withStyles from "@material-ui/core/styles/withStyles";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

class SectionPrivacy extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.description} style={{ color: "#508d24" }}>
            <b>Polityka Prywatności.</b>
            </h3>
            <h3 className={classes.description} style={{ color: "#508d24" }}>
            <b>KLAUZULA INFORMACYJNA O PRZETWARZANIU DANYCH OSOBOWYCH.</b>
            </h3>
            <h5 className={classes.description}>
            Zgodnie z art. 13 ust. 1 i 2 oraz art. 14 ust 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, RODO), informuję iż:
            <ol>
              <li>Administratorem Pana/-i danych będzie firma <b>HORTUS</b> z siedzibą w Ełku, ul. Armii Krajowej 11A/28 19-300 EŁK.</li>
              <li><b>HORTUS</b> nie powołała inspektora ochrony danych osobowych w rozumieniu rozdziału IV sekcji 4 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</li>
              <li>Dane osobowe, pozyskane za pomocą formularza, obejmujące: imię i nazwisko, adres e-mail, numer telefonu Dane są zbierane w konkretnym celu i przetwarzane w minimalnym zakresie umożliwiającym realizację ściśle określonego celu.</li>
              <li>Zgoda na przetwarzanie danych osobowych zawsze pozyskiwana jest w sposób przejrzysty, rzetelny i zgodny z prawem. Dane osobowe są przetwarzania w celu realizacji operacji wynikających z przepisów prawa oraz szeregu ustaw nakładających na administratora obowiązki i zadania, których realizacja wymaga przetwarzania danych osobowych.</li>
              <li>Pani/Pana dane osobowe będą przechowywane przez okres niezbędny do obsługi zgłoszenia zawartego w korespondencji, do czasu utrzymania celu operacji, a w pozostałym zakresie do wycofania zgody na przetwarzanie, na które zgoda została wyrażona Odbiorcą Pani/ Pana danych osobowych będzie firma <b>HORTUS</b> a także podmioty współpracujące z tą firmą , czyli inne podmioty przetwarzające, upoważnione na podstawie przepisów prawa.</li>
              <li>Posiada Pani/Pan następujące uprawnienia dotyczące Pani/Pana danych osobowych:</li>
                <ul>
                  <li>prawo do informacji,</li>
                  <li>prawo dostępu do danych osobowych,</li>
                  <li>prawo do sprostowania lub uzupełnienia niekompletnych danych osobowych,</li>
                  <li>prawo do usunięcia danych osobowych,</li>
                  <li>prawo do ograniczenia przetwarzania,</li>
                  <li>prawo do bycia powiadomionym o sprostowaniu lub usunięciu danych lub o ograniczonym ich przetwarzaniu,</li>
                  <li>prawo do przenoszenia danych osobowych,</li>
                  <li>prawo do sprzeciwu,</li>
                  <li>prawo do tego, by nie podlegać profilowaniu.</li>
                </ul>
              <li>Ma Pani/Pan prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, że przetwarzanie Pani/Pana danych osobowych narusza przepisy prawa z zakresu ochrony danych osobowych.</li>
              <li>Podanie przez Panią/Pana danych osobowych było dobrowolne.</li>
              <li>Brak podania danych będzie skutkowało brakiem możliwości realizacji umowy bądź realizacji działań prowadzonych na podstawie udzielonej zgody.</li>
              <li>Podanie przez Panią/Pana danych osobowych, w zależności od ściśle określonego celu przetwarzania, może być wymogiem ustawowym lub umownym lub warunkiem zawarcia umowy. Pani/Pana dane osobowe nie będą wykorzystane do zautomatyzowanego podejmowania decyzji ani profilowania,</li>
              <li>Ma Pani/Pan prawo wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania Pani/Pana danych osobowych ze względu na szczególną Pani/Pana sytuację.</li>
              <li>Ma Pani/Pan prawo wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania Pani/Pana danych osobowych na potrzeby marketingu bezpośredniego, w tym profilowania.</li>
            </ol>
            </h5>

            <h3 className={classes.description} style={{ color: "#508d24" }}>
            <b>POLITYKA PRYWATNOŚCI I WYKORZYSTYWANIA PLIKÓW COOKIES.</b>
            </h3>
            <h5 className={classes.description}>
            <ol>
              <li>Serwis zbiera w sposób automatyczny tylko informacje zawarte w plikach cookies.</li>
              <li>Pliki (cookies) są plikami tekstowymi, które przechowywane są w urządzeniu końcowym użytkownika serwisu. Przeznaczone są do korzystania ze stron serwisu. Przede wszystkim zawierają nazwę strony internetowej swojego pochodzenia, swój unikalny numer, czas przechowywania na urządzeniu końcowym.</li>
              <li>Operator serwisu <b>HORTUS</b> jest podmiotem zamieszczającym na urządzeniu końcowym swojego użytkownika pliki cookies oraz mającym do nich dostęp.</li>
              <li>Operator serwisu wykorzystuje pliki (cookies) w celu:</li>
                <ul>
                  <li>dopasowania zawartości strony internetowej do indywidualnych preferencji użytkownika, przede wszystkim pliki te rozpoznają jego urządzenie, aby zgodnie z jego preferencjami wyświetlić stronę;</li>
                  <li>przygotowywania statystyk pomagających poznaniu preferencji i zachowań użytkowników, analiza tych statystyk jest anonimowa i umożliwia dostosowanie zawartości i wyglądu serwisu do panujących trendów, statystyki stosuje się też do oceny popularności strony;</li>
                </ul>
              <li>Serwis stosuje dwa zasadnicze rodzaje plików (cookies) – sesyjne i stałe. Pliki sesyjne są tymczasowe, przechowuje się je do momentu opuszczenia strony serwisu (poprzez wejście na inną stronę, wylogowanie lub wyłączenie przeglądarki). Pliki stałe przechowywane są w urządzeniu końcowym użytkownika do czasu ich usunięcia przez użytkownika lub przez czas wynikający z ich ustawień.</li>
              <li>Użytkownik może w każdej chwili dokonać zmiany ustawień swojej przeglądarki, aby zablokować obsługę plików (cookies) lub każdorazowo uzyskiwać informacje o ich umieszczeniu w swoim urządzeniu. Inne dostępne opcje można sprawdzić w ustawieniach swojej przeglądarki internetowej. Należy pamiętać, że większość przeglądarek domyślnie jest ustawione na akceptację zapisu plików (cookies) w urządzeniu końcowym.</li>
              <li>Pliki (cookies) z których korzysta serwis (zamieszczane w urządzeniu końcowym użytkownika) mogą być udostępnione jego partnerom oraz współpracującym z nim reklamodawcą.</li>
              <li>Informacje dotyczące ustawień przeglądarek internetowych dostępne są  na stronie jej producenta.</li>
            </ol>
            </h5>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default withStyles(productStyle)(SectionPrivacy);
