
import React from "react";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";


class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
          <GridItem cs={12} sm={12} md={12}>
            <h3 className={classes.description} style={{ color: "#508d24" }}>
            <b>Firma HORTUS Architektura Zieleni i Środowisko obecna jest na rynku od 2008 roku.</b>
            </h3>
            <h5 className={classes.description}>
            W tym czasie stale poszerzaliśmy zakres oferowanych usług.
            Firma prowadzona jest przez mgr inż. Architektury Krajobrazu posiadającą uprawnienia <strong>Inspektora Nadzoru Prac w Terenach Zieleni</strong> oraz <br/> <b>Inspektora Nadzoru Dendrologicznego w Procesie Inwestycyjnym</b>.
            </h5>
            <h5 className={classes.description}>
            Od początku swojej działalności zajmujemy się pracami z zakresu <strong>architektury krajobrazu</strong> oraz ochrony środowiska. Prowadzimy kompleksową obsługę  zieleni przydomowej oraz zieleni użyteczności publicznej, poprzez inwentaryzację, projekt, realizację, aż po pielęgnację. Dodatkowo wykonujemy opracowania z zakresu ochrony środowiska oraz oferujemy nadzór nad wykonywaniem zieleni przez <strong>Inspektora Nadzoru Prac w Terenach Zieleni</strong> oraz <b>Inspektora Nadzoru Dendrologicznego w Procesie Inwestycyjnym</b> z uprawnieniami. Oferujemy również wynajem sprzętu takiego jak: minikoparka, koparka i koparko-ładowarka.
            </h5>
            <h3 className={classes.description} style={{ color: "#508d24" }}>
            <b>Gwarantujemy pełne zaangażowanie oraz rzetelne i terminowe wykonywanie powierzonych zadań!</b>
            </h3><br />
          </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

TeamSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(TeamSection);
