import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CardMedia from '@material-ui/core/CardMedia';
import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import Carousel from "react-slick";
import ReactPlayer from 'react-player/lazy'
//import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from 'video-react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

//import image1 from "assets/img/bg.jpg";
//import image2 from "assets/img/bg2.jpg";
//import image3 from "assets/img/bg3.jpg";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import ProductSection from "./Sections/ProductSection.jsx";
import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
//import SectionCarousel from "./Sections/SectionCarousel.jsx";

const dashboardRoutes = [];

class LandingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isReady: false
    };

    this.playerRef = React.createRef(); // Create a ref for the player
  }

  onReady = () => {
    if (!this.state.isReady) {
      const timeToStart = 9
      const player = this.playerRef.current.getInternalPlayer();
      if (player) {
        player.currentTime = timeToStart;
      }
      this.setState({ isReady: true })
    }
  }
  
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hortus_Architektura_Zieleni_Ochrona_srodowiska_Elk.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ReactPlayer
                  ref={this.playerRef}
                  playing={true}
                  url='img/Hortus.mp4'
                  width='100%'
                  height='100%'
                  controls
                  volume = {0.1}
                  playIcon={<PlayCircleFilledIcon/>}
                  onReady={this.onReady}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className={classes.title} >
                  Architektura Krajobrozu Ochrona Środowiska
                </h1>
                <h3>
                 Inspektor Nadzoru Prac w Terenach Zieleni <br/>
                 Inspektor Nadzoru Dendrologicznego w Procesie Inwestycyjnym.
                </h3>
                <Button
                  color="success"
                  size="lg"
                  href="/#kontakt"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-envira" />
                  Kontakt
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
        <div  id="o-nas" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} style={{paddingTop: "20px"}}>
           
             {/* 
              <ReactPlayer
                playing
                url='img/Hortus.mp4'
                width='100%'
                height='100%'
                controls
                volume = {0.2}
                playIcon={<PlayCircleFilledIcon/>}
              />
               */}
            </GridItem>
          <TeamSection />
        </div>
          <div className="bgimg-1">
            <div id="projektowanie" className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "9%" }}>
                  <Card style={{  opacity: "90%" }}>
                  <CardContent>
                    <h3 style={{ color: "#508d24", textAlign: "center"  }}>
                      <b>PROJEKTOWANIE I PIELĘGNACJA ​OGRODÓW PRZYDOMOWYCH, ZIELENI MIEJSKIEJ I PRZY FIRMACH</b>
                    </h3>
                    <br />
                      <Typography variant="h5" color="textPrimary"  style={{ textAlign: "center"  }}>
                      W zakres naszej działalności wchodzą ogrody przydomowe, zieleń miejska, zieleń przy drogach i zagospodarowywanie terenów zieleni przy firmach. ​Staramy się, by wykonywane przez nas projekty były nie tylko piękne, ale także funkcjonalne i dostosowywały się do warunków naturalnych.
                      </Typography>
                    </CardContent>
                  </Card>
                </GridItem>
            </div>
          </div>
          <GridContainer justify="center" style={{ width: "100%",  marginRight: "0px",  marginLeft: "0px" }}>
            <GridItem cs={12} sm={12} md={6} style={{  paddingLeft: "0px",  paddingRight: "0px" }}>
              <CardMedia
                component="img"
                image="img/hortus-projektowanie-zieleni-Elk.jpg"
                alt="Projektowanie Zieleni Hortus Ełk"
                title="Projektowanie Zieleni Hortus Ełk"
              />
            </GridItem>
            <GridItem cs={12} sm={12} md={6} >
                <h2 style={{ color: "#508d24" }}>
                  <b>Usługi projektowe:</b>
              </h2>
              <ul>
                <Typography variant="h5" color="textPrimary">
                  <li>inwentaryzacja zieleni,</li>
                  <li>projektowanie ogrodów przydomowych,</li>
                  <li>projektowanie zieleni miejskiej,</li>
                  <li>projektowanie zieleni przy firmach,</li>
                  <li>projektowanie zieleni na dachach i tarasach,</li>
                  <li>projektowanie oświetlenia,</li>
                  <li>projektowanie automatycznego nawadniania,</li>
                  <li>wycena zieleni, kosztorysy.</li>
                </Typography>
              </ul>
            </GridItem>
          </GridContainer>

          <div className="bgimg-2">
            <div id="wykonanie" className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "9%" }}>
                <Card style={{  opacity: "90%" }}>
                <CardContent>
                  <h2 style={{ color: "#508d24", textAlign: "center"  }}>
                    <b>Usługi w zakresie wykonania zieleni</b>
                  </h2>
                  <br />
                  <ul>
                    <Typography variant="h6" color="textPrimary" >
                      <li>zakładanie trawników,</li>
                      <li>wykonywanie nasadzeń (drzewa, krzewy, byliny),</li>
                      <li>wykonywanie elementów wodnych (kaskady, oczka wodne),</li>
                      <li>wykonywanie oświetlenia i małej architektury,</li>
                      <li>systemy automatycznego nawadniania,</li>
                      <li>budowa różnych typów nawierzchni (układnie podjazdów, ścieżek, chodników, parkingów),</li>
                      <li>sadzenie i przesadzanie dużych drzew,</li>
                      <li>wykonywanie zielonych dachów.</li>
                    </Typography>
                  </ul>
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          </div>


          <GridContainer id="zielen-drogowa" justify="center" style={{ width: "100%",  marginRight: "0px",  marginLeft: "0px" }}>
            <GridItem cs={12} sm={12} md={6} style={{  paddingLeft: "0px",  paddingRight: "0px" }}>
              <CardMedia
                component="img"
                image="img/Architektura-Zieleni-Elk.jpg"
                alt="Projektowanie Zieleni Hortus Ełk"
                title="Projektowanie Zieleni Hortus Ełk"
              />
              {/*
                <div className="slanted"><i className="fab fa-envira" style={{paddingLeft: '35%', paddingTop: '13%', color: 'white', fontSize: '200px'}}></i></div>
                 <Card >
                  <Carousel {...settings}>
                    <div>
                      <img
                        src={image1}
                        alt="First slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                    <div>
                      <img
                        src={image2}
                        alt="Second slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                    <div>
                      <img
                        src={image3}
                        alt="Third slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                  </Carousel>
                </Card>
               */}
              </GridItem>
              <GridItem cs={12} sm={12} md={6}>
                  <h2 style={{ color: "#508d24" }}>
                    <b>Usługi w zakresie zieleni drogowej:</b>
                  </h2>
                  <ul>
                    <Typography variant="h5" color="textPrimary">
                      <li>humusowanie skarp, przeciwskarp i rowów,</li>
                      <li>układanie geosyntetyków i krat betonowych na skarpach,</li>
                      <li>zakładanie trawników na skarpach i terenach płaskich,</li>
                      <li>koszenie trawy na rowach, skarpach i terenach płaskich,</li>
                      <li>nasadzenia drzew, krzewów i pnączy.</li>
                    </Typography>
                  </ul>

              </GridItem>
            </GridContainer>

            <div className="bgimg-3">
            <div id="pielegnacja" className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "9%" }}>
                <Card style={{  opacity: "90%" }}>
                <CardContent>
                  <h2 style={{ color: "#508d24", textAlign: "center"  }}>
                    <b>Usługi w zakresie pielęgnacji i utrzymania zieleni:</b>
                  </h2>
                  <br />
                  <Typography variant="h5" color="textPrimary">
                  kompleksowa pielęgnacja i utrzymanie zieleni założonej i istniejącej w tym m.in.:
                  </Typography>
                  <ul>
                    <Typography variant="h6" color="textPrimary" >
                        <li>koszenie i pielęgnacja trawników,</li>
                    <li>odchwaszczanie,</li>
                    <li>nawożenie,</li>
                    <li>przycinanie drzew i krzewów,</li>
                    <li>przygotowywanie roślin przed okresem zimowym,</li>
                    <li>odśnieżanie.</li>
                    </Typography>
                  </ul>
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          </div>

          <GridContainer id="ochrona" justify="center" style={{ width: "100%",  marginRight: "0px",  marginLeft: "0px" }}>
            <GridItem cs={12} sm={12} md={6} style={{  paddingLeft: "0px",  paddingRight: "0px" }}>
            <div className="slanted"><i className="fab fab fa-pagelines" style={{paddingLeft: '35%', paddingTop: '13%', color: 'white', fontSize: '200px'}}></i></div>

               {/* <Card >
                  <Carousel {...settings}>
                    <div>
                      <img
                        src={image1}
                        alt="First slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                    <div>
                      <img
                        src={image2}
                        alt="Second slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                    <div>
                      <img
                        src={image3}
                        alt="Third slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                      </div>
                    </div>
                  </Carousel>
                </Card>
              */}
              </GridItem>
            <GridItem cs={12} sm={12} md={6} >
                  <h2 style={{ color: "#508d24" }}>
                  <b>Opracowania z zakresu ochrony środowiska:</b>
                </h2>
                <ul>
                  <Typography variant="h6" color="textPrimary" >
                      <li>oceny oddziaływania na środowisko do planów miejscowych,</li>
                  <li>raporty oddziaływania na środowisko,</li>
                  <li>operaty wodnoprawne.</li>
                  </Typography>
                </ul>
              </GridItem>
          </GridContainer>

          <div className="bgimg-4">
            <div id="wynajem-sprzetu" className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "9%" }}>
                <Card style={{  opacity: "90%" }}>
                <CardContent>
                  <h2 style={{ color: "#508d24", textAlign: "center"  }}>
                    <b>Wynajem sprzętu:</b>
                  </h2>
                  <ul>
                    <Typography variant="h6" color="textPrimary" >
                      <li>koparko-ładowarka JCB,</li>
                      <li>minikoparka JCB,</li>
                      <li>bobcat,</li>
                    </Typography>
                  </ul>
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          </div>
          <div className={classes.container}>
            <WorkSection />
          </div>
        </div>
        <Footer />
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ akceptuję"
            cookieName="hellasfonCookie"
            style={{ background: "#4caf50" }}
            buttonStyle={{ background: "#386f3a", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Używamy plików cookie na tej stronie, aby poprawić komfort użytkowania.{" "}
            <span style={{ fontSize: "12px" }}>
            Kliknięcie dowolnego linku na tej stronie wyraża zgodę na pliki cookie.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Więcej informacji</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(LandingPage);
