import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-recaptcha";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class WorkSection extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.checkCaptacha = this.checkCaptacha.bind(this);
    this.state = {
      name: "",
      email: "",
      message: "",
      accept: false,
      isVerified: false,
      recaptchaClass: "",
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      errors: {
        name: false,
        email: false,
        message: false,
        accept: false
      }
    };
  }

  checkCaptacha(response) {
    if (response) {
      this.setState({ isVerified: true, recaptchaClass: "" });
    }
  }
  onloadCaptcha() {
    console.log("recaptcha loaded");
  }
  onSubmit = async e => {
    e.preventDefault();

    const { name, email, message, accept, isVerified, loading } = this.state;
    const { classes } = this.props;
    const newContact = {
      name,
      email,
      message
    };
    const login = {
      fsd5345345gdfg: process.env.REACT_APP_API_KEY,
    };

    // Check For Errors
    if (name === "") {
      this.setState({ errors: { name: true } });
      return;
    }

    if (email === "") {
      this.setState({ errors: { email: true } });
      return;
    }

    if (message === "") {
      this.setState({ errors: { message: true } });
      return;
    }

    if (accept === false) {
      this.setState({ errors: { accept: true } });
      return;
    }
    if (!isVerified) {
      this.setState({ recaptchaClass: "recaptcha-error" });
      return;
    }


    if (!loading) {
      this.setState({ loading: true });
      // get the authorization api token
      try {

        const res = await axios.post(`https://api.in4system.com/api/gfd454534hjh7879kjllkp089758mnhb`, login);
        const header = {
          'Authorization': 'Bearer ' + res.data.token
        }
        // sent email

        try {
          await axios.post(
            `https://api.in4system.com/api/gfd454534hjh7879kjllkp089758mnhb125`,
            newContact, { headers: header }
          );
          //console.log(res.data);
          this.setState({
            loading: false,
            classSnackbar: classes.successSnackbar,
            response: "Wiadomość wysłana poprawnie."
          });

          // clear state
          this.setState({
            name: "",
            email: "",
            message: "",
            accept: false,
            isVerified: false,
            recaptchaClass: "",
            loading: false,
            errors: {
              name: false,
              email: false,
              message: false,
              accept: false
            }
          });
        } catch (e) {
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Coś poszło nie tak spróbuj ponownie."
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response: "Coś poszło nie tak spróbuj ponownie."
        });
      }
    }
  };
  onChange = e => {
    this.setState(
      e.target.id === "accept"
        ? {
            [e.target.id]: e.target.checked
          }
        : { [e.target.id]: e.target.value }
    );
  };
  onFocus = e => {
    this.setState(
      e.target.value === "" && e.target.id === "name"
        ? {
            errors: {
              name: true
            }
          }
        : e.target.value === "" && e.target.id === "email"
        ? {
            errors: {
              email: true
            }
          }
        : e.target.value === "" && e.target.id === "message"
        ? {
            errors: {
              message: true
            }
          }
        : e.target.value === "" && e.target.id === "accept"
        ? {
            errors: {
              accept: true
            }
          }
        : {
            errors: {
              name: false,
              email: false,
              message: false,
              accept: false
            }
          }
    );
  };
  onBlur = e => {
    this.setState(
      e.target.value === "" && e.target.id === "name"
        ? {
            errors: {
              name: true
            }
          }
        : e.target.value === "" && e.target.id === "email"
        ? {
            errors: {
              email: true
            }
          }
        : e.target.value === "" && e.target.id === "message"
        ? {
            errors: {
              message: true
            }
          }
        : e.target.value === "" && e.target.id === "accept"
        ? {
            errors: {
              accept: true
            }
          }
        : {
            errors: {
              name: false,
              email: false,
              message: false,
              accept: false
            }
          }
    );
  };

  onClick = () =>
    this.setState({
      accept: !this.state.accept
    });

  render() {
    const { classes } = this.props;
    const {
      name,
      email,
      errors,
      message,
      accept,
      recaptchaClass,
      loading,
      classSnackbar,
      response
    } = this.state;
    return (
      <div id="kontakt" className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title} style={{ color: "#508d24", textAlign: "center"  }}>Kontakt</h2>
            <h5 className={classes.descriptionTitle}>
              <b>Usługi świadczymy na terenie całej Polski</b>, współpracując z osobami
              prywatnymi, firmami, instytucjami oraz spółdzielniami
              mieszkaniowymi. Aby poznać więcej szczegółów naszej oferty lub
              umówić się z nami na spotkanie, prosimy o kontakt telefoniczny
              bądź mailowy!
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem cs={12} sm={12} md={6}>
            <h2 className={classes.description}>HORTUS</h2>
            <h4 className={classes.description}>
              ARCHITEKTURA ZIELENI I ŚRODOWISKO
            </h4>
            <List>
              <ListItem>
                <ListItemIcon className={classes.iconBig}>
                  <i className="fas fa-address-card" />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="UL. Armii Krajowej 11A/28 19-300 EŁK"
                  secondary="WOJ. WARMIŃSKO-MAZURSKIE"
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.iconBig}>
                  <i className="fas fa-phone" />
                </ListItemIcon>
                <a href="tel:+48508216184">
                  <ListItemText
                    className={classes.description}
                    primary="+48 508 216 184"
                  ></ListItemText>
                </a>
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.iconBig}>
                  <i className="fas fa-phone" />
                </ListItemIcon>
                <a href="tel:+48503878603">
                  <ListItemText
                    className={classes.description}
                    primary="+48 503 878 603"
                  ></ListItemText>
                </a>
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.iconBig}>
                  <i className="fas fa-envelope" />
                </ListItemIcon>
                <a href="mailto:hortus.galazka@gmail.com">
                  <ListItemText
                    className={classes.description}
                    primary="HORTUS.GALAZKA@GMAIL.COM"
                  ></ListItemText>
                </a>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <h4 className={classes.description}>NIP:</h4>
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="8481210599"
                ></ListItemText>
              </ListItem>
            </List>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <form onSubmit={this.onSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={errors.name ? true : false}
                    required
                    type="text"
                    labelText="Imię i Nazwisko"
                    id="name"
                    name="name"
                    value={name}
                    disabled={loading}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={errors.email ? true : false}
                    required
                    type="email"
                    labelText="Email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    disabled={loading}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <CustomInput
                  error={errors.message ? true : false}
                  required
                  type="text"
                  labelText="Wiadomość"
                  id="message"
                  name="message"
                  value={message}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  disabled={loading}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5
                  }}
                />
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          required
                          id="accept"
                          name="accept"
                          checked={accept}
                          onClick={this.onClick}
                          onChange={this.onChange}
                          disabled={loading}
                        />
                      }
                      label={
                        <Typography className={classes.labelCheckbox}>
                          Potwierdzam, że zapoznałam/em się z klauzulą
                          informacyjną dotyczącą przetwarzania danych osobowych
                          oraz wyrażam zgodę na przetwarzanie moich danych
                          osobowych przez HORTUS w celu i zakresie niezbędnym do
                          obsługi zapytania. Zostałem poinformowana/ny, iż
                          administratorem moich danych osobowych jest HORTUS z
                          siedzibą przy ul. Armii Krajowej 11A/28 w Ełku oraz że
                          udostępnienie danych jest dobrowolne, mam prawo wglądu
                          do treści danych oraz żądania ich poprawienia bądź
                          usunięcia, a dane przesłane za pomocą formularza
                          wykorzystane zostaną wyłącznie do kontaktu zwrotnego
                          ze mną. <a href="/privacy">Polityka prywatności</a>
                        </Typography>
                      }
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={recaptchaClass}>
                    <ReCAPTCHA
                      size="normal"
                      render="explicit"
                      sitekey="6Ld3Gl8aAAAAAK5lUJp_s1bUGfzwpNATSLIrKMdA"
                      onloadCallback={this.onloadCaptcha}
                      verifyCallback={this.checkCaptacha}
                    />
                  </div>
                </GridItem>

                <GridContainer className={classes.buttonContainer}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.textCenter}
                  >
                    <Button
                      fullWidth
                      type="submit"
                      color="success"
                      disabled={loading}
                      variant="contained"
                    >
                      Wyślij
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </GridItem>
                </GridContainer>
            </form>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

WorkSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(WorkSection);
